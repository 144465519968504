<template>
	<div class="d-flex mb-2 pb-20 mb-sm-10">
		<ul class="step-list d-flex flex-row flex-wrap align-items-start align-items-sm-center m-0 p-0">
			<li v-for="(step, index) in this.form_steps"
				:key="step.id"
				class="nav-item d-flex flex-column align-items-center me-10 me-sm-20 mb-2">
				<button class="btn btn-outline d-flex align-items-center p-0 rounded-pill opacity-1"
						@click.prevent.stop="this.setStep(step.id)">
									<span class=" d-flex align-items-center justify-content-center rounded-circle me-1 lh-1"
										  :class="(this.form_step === step.id) ? 'border border-primary text-primary' : 'border border-font-light text-font-light'"
										  style="width: 50px; height: 50px; border-width: 2px;"
										  v-text="(index+1)" />
					<span class="font-semi fw-semi px-2"
						  :class="(this.form_step === step.id) ? 'text-primary' : 'text-font-light'"
						  :style="(this.form_step !== step.id) ? 'text-decoration: underline' : null"
						  v-text="step.title" />
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
    export default {
        name: 'FormSteps',
		props: {
			formStep: {
				type: [Number, String]
			},
			formSteps: {
				type: Array
			},
        },
        components: {

        },
		methods: {
			setStep(step) {
				if (typeof step !== "undefined") {
					this.form_step = step;
					this.$emit('setStep', step);
				}
			}
		},
        computed: {
			form_step() {
				return this.formStep
			},
			form_steps() {
				return this.formSteps
			},
        }
    }
</script>