<template>
    <div class="add-client position-relative">
		<div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative">
			<div class="page-header d-flex align-content-center align-items-center">
				<h1 v-if="this.client_id" class="page-title mb-0 me-auto" v-text="this.title['edit']" />
				<h1 v-else class="page-title mb-0 me-auto" v-text="this.title['default']" />
				<a href="javascript:{}"
				   class="d-flex flex-row align-content-center ml-auto ms-auto me-0"
				   @click="this.goBack()">
					<span aria-label="Вернуться назад"
						  class="btn btn-outline-secondary bg-light-gray me-10 border border-brd-primary rounded-3"
						  style="padding: 0.75rem 0.95rem;">
						<img :src="require('@/assets/icons/undo.svg')" alt="Назад" width="18" height="18">
					</span>
					<span class="my-auto text-font-light">Назад</span>
				</a>
			</div>
			<div v-if="this.is_loading_process" class="d-flex vh-60 my-0 align-items-center">
				<Loader />
			</div>
			<form v-else class="border border-brd-primary border-start-0 border-end-0 border-bottom-0 py-4 mt-3 needs-validation"
				  :data-client-id="this.client_id" novalidate>

				<div v-if="this.user_cant_edit" class="alert alert-danger">
					<img :src="require('@/assets/icons/warning.svg')" class="d-inline-block me-1" alt="Ошибка" width="14" height="14" style="margin-top: -3px" />
					Недостаточно прав для редактирования данного Клиента.
				</div>

				<div class="px-0">

					<FormSteps :formSteps="this.form_steps"
							   :formStep="this.form_step"
							   @setStep="(step) => this.setEvent(step)" />

					<!-- Звонок -->
                    <div>
		
		
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-3">
				<div class="modal-header d-flex mb-20 border-0 p-0">
					<span v-if="this.event_type == 'call'"
						  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
						  v-text="(this.event_id) ? 'Звонок' : 'Новый звонок'" />

					<span v-else-if="this.event_type == 'meet'"
						  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
						  v-text="(this.event_id) ? 'Встреча' : 'Новая встреча'" />

					<span v-else-if="this.event_type == 'show'"
						  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
						  v-text="(this.event_id) ? 'Показ' : 'Новый показ'" />

					<span v-else-if="this.event_type == 'deal'"
						  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
						  v-text="(this.event_id) ? 'Сделка' : 'Новая сделка'" />

					<span v-else-if="this.event_type == 'even'"
						  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
						  v-text="(this.event_id) ? 'Задача' : 'Новая задача'" />

					<span v-else-if="this.event_type == 'file'"
						  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
						  v-text="(this.event_id) ? 'Файлы' : 'Добавить файлы'" />

					<span v-else-if="this.event_type == 'partner_id'"
						  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
						  v-text="(this.event_id) ? 'Партнёр' : 'Новый партнёр'" />

					<span v-else
						  class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90"
						  v-text="(this.event_id) ? 'Задача' : 'Новая задача'" />

					
				</div>
				<div class="modal-body d-flex flex-column p-0">
					<form class="form row-fluid row-cols-1 needs-validation" novalidate>
						<div v-if="this.inArray(this.event_type, ['call', 'meet','show','deal','even'])" class="col">
							<div v-if="!this.isEmpty(this.item.is_tracking) ? Boolean(this.item.is_tracking) : false">
								<div class="row-fluid row-cols-auto align-items-center flex-row mb-3">
									<DateTimePicker inputId="schedule_date"
													inputName="schedule_date"
													ref="schedule_date"
													inputLabel="Выберите дату и время с:"
													:pickTime="true"
													:hasInputElement="false"
													:use12HourClock="false"
													inputRequired="true"
													:inputValue="(!this.isEmpty(this.item.schedule_date)) ? this.item.schedule_date : new Date().toDateString()"
													@setValue="(value) => this.setAndValidate('schedule_date', value)" />
								</div>
								<div class="row-fluid row-cols-auto align-items-center flex-row mb-3">
									<DateTimePicker inputId="schedule_date_to"
													inputName="schedule_date_to"
													inputLabel="Выберите дату и время по:"
													ref="schedule_date_to"
													:pickTime="true"
													:hasInputElement="false"
													:use12HourClock="false"
													inputRequired="true"
													:inputValue="(!this.isEmpty(this.item.schedule_date)) ? this.item.schedule_date_to : new Date().toDateString()"
													@setValue="(value) => this.setAndValidate('schedule_date_to', value)" />
								</div>
							</div>
							<div v-else class="row-fluid row-cols-auto align-items-center flex-row mb-3">
								<DateTimePicker inputId="schedule_date"
												inputName="schedule_date"
												ref="schedule_date"
												inputLabel="Выберите дату и время:"
												:pickTime="true"
												:hasInputElement="false"
												:use12HourClock="false"
												inputRequired="true"
												:inputValue="(!this.isEmpty(this.item.schedule_date)) ? this.item.schedule_date : null"
												@setValue="(value) => this.setAndValidate('schedule_date', value)" />
							</div>
						</div>
						<div v-if="this.inArray(this.event_type, ['call'])" class="col">
							<Switcher inputId="is_tracking"
									  inputName="is_tracking"
									  ref="is_tracking"
									  inputLabel="Связь с телефонией"
									  :inputValue="!this.isEmpty(this.item.is_tracking) ? this.item.is_tracking : null"
									  inputClass="px-0 mb-3"
									  inputWrapClass="flex-column mb-3"
									  @setValue="(value) => this.setAndValidate('is_tracking', !Boolean(this.item.is_tracking))" />
						</div>
						<div v-if="this.inArray(this.event_type, ['deal'])" class="col">
							<InputText inputId="summ"
									   inputLabel="Сумма:"
									   inputName="summ"
									   ref="summ"
									   :inputValue="(!this.isEmpty(this.item.summ)) ? this.item.summ : null"
									   inputClass="rounded-1 p-2"
									   inputRequired="true"
									   inputWrapClass="flex-column mb-2"
									   @setValue="(value) => this.setAndValidate('summ', value)"/>
						</div>
						<div v-if="this.inArray(this.event_type, ['meet', 'show'])" class="col">
							<InputText inputId="address"
									   inputLabel="Адрес:"
									   inputName="address"
									   ref="address"
									   :inputValue="(!this.isEmpty(this.item.address)) ? this.item.address : null"
									   inputClass="rounded-1 p-2"
									   inputRequired="true"
									   inputWrapClass="flex-column mb-2"
									   @setValue="(value) => this.setAndValidate('address', value)"/>
						</div>
						<div v-if="this.inArray(this.event_type, ['deal'])" class="col">
							
							<SelectBox v-if="this.inArray(this.section, ['clients'])" inputId="object_id"
									   inputName="object_id"
									   ref="object_id"
									   inputLabel="Объект:"
									   :inputOptions="this.objects_list"
									   :inputValue="(!this.isEmpty(this.item.object_id)) ? this.item.object_id : null"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputSearch="true"
									   inputWrapClass="mb-20"
									   @setValue="(value) => this.setAndValidate('object_id', value)" />
							<SelectBox v-else-if="this.inArray(this.section, ['objects', 'requisitions'])" inputId="client_id"
									   inputName="client_id"
									   ref="client_id"
									   inputLabel="Клиент:"
									   :inputOptions="this.clients_list"
									   :inputValue="(!this.isEmpty(this.item.client_id)) ? this.item.client_id : null"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputSearch="true"
									   inputWrapClass="mb-20"
									   @setValue="(value) => this.setAndValidate('client_id', value)" />
							
							<SelectBox inputId="document_id"
									   inputName="document_id"
									   ref="document_id"
									   inputLabel="Бланк документа:"
									   :inputOptions="this.documents_list"
									   :inputValue="(!this.isEmpty(this.item.document_id)) ? this.item.document_id : null"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputSearch="true"
									   inputWrapClass="mb-20"
									   @setValue="(value) => this.setAndValidate('document_id', value)" />
						</div>
						<div v-if="this.inArray(this.event_type, ['even', 'file'])" class="col">
							<InputText inputId="title"
									   inputLabel="Название:"
									   inputName="title"
									   ref="title"
									   :inputValue="(!this.isEmpty(this.item.title)) ? this.item.title : null"
									   inputClass="rounded-1 p-2"
									   inputRequired="true"
									   inputWrapClass="flex-column mb-2"
									   @setValue="(value) => this.setAndValidate('title', value)"/>
						</div>
						<div v-if="this.inArray(this.event_type, ['file'])" class="col">
							<InputFile inputId="files"
									   inputLabel="Файлы:"
									   inputName="files"
									   ref="files"
									   :inputValue="(!this.isEmpty(this.item.files)) ? this.item.files : null"
									   inputClass="rounded-1 p-2"
									   inputWrapClass="flex-column mb-2"
									   inputRemove="true"
									   inputMultiple="true"
									   inputRequired="true"
									   @removeValue="(value) => this.removeFile('files', value)"
									   @setValue="(value) => this.uploadFile('files', value)" />

						</div>
						<div v-if="this.inArray(this.event_type, ['call', 'meet', 'show', 'deal', 'even']) && this.isEmpty(this.eventId)" class="col">
							<SelectBox inputId="responsible_id"
									   inputName="responsible_id"
									   ref="responsible_id"
									   inputLabel="Ответственный:"
									   :inputOptions="this.responsibles_list"
									   :inputValue="(!this.isEmpty(this.who_work_responsibles)) ? this.who_work_responsibles : null"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputSearch="true"
									   inputMultiple="true"
									   inputWrapClass="mb-20"
									   @setValue="(value) => this.setAndValidate('responsible_id', value)" />
						</div>
						<div v-if="this.inArray(this.event_type, ['call', 'meet', 'show', 'deal', 'even', 'file'])" class="col">
							<TextArea inputId="comment"
									  inputName="comment"
									  ref="comment"
									  inputLabel="Комментарий:"
									  :inputValue="(!this.isEmpty(this.item.comment)) ? this.item.comment : null"
									  inputLabelClass="text-font-secondary mb-2"
									  inputClass="rounded-3 p-2"
									  inputWrapClass="mb-20"
									  @setValue="(value) => this.setAndValidate('comment', value)" />
						</div>
						
					</form>
				</div>
			</div>
		</div>
	</div>
                           
					<!-- /Звонок -->


				</div>

				<button
					type="button"
					class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
					:disabled="this.isEmpty(this.event_type)"
					@click="this.addEditEvent()"
					v-text="(this.item.id) ? 'Сохранить' : 'Добавить'" />

			</form>
		</div>
	</div>

	
	

	<ResultsModal id="addTask"
				  :state="this.resultsModalState.state"
				  :title="this.resultsModalState.title"
				  :message="this.resultsModalState.message"
				  :successButton="(!this.isEmpty(this.object_id) || this.$props.returnPath == '/add-object') ? 'Продолжить' : 'К списку клиентов'"
				  :successAction="() => this.goBack()" />

	<HistoryTasksModal />

	

</template>

<script>
    import CommonService from "../services/CommonService";
	import ConfirmModal from "@/components/modals/ConfirmModal";
	import SelectBox from "@/components/inputs/SelectBox";
	import InputText from "@/components/inputs/InputText";
	import TextArea from "@/components/inputs/TextArea";
	import Switcher from "@/components/inputs/Switcher";
	import api from "@/api";
	import * as bootstrap from "bootstrap";
	import FormSteps from "@/components/common/FormSteps";
	import ObjectsList from "@/components/objects/ObjectsList";
	import AddEditContractModal from "@/components/modals/AddEditContractModal";
	import ResultsModal from "@/components/modals/ResultsModal";
	import RequestCard from "@/components/requisitions/RequestCard";
	import Loader from "@/components/common/Loader";
	//import InputNumber from "@/components/inputs/InputNumber";
	import InnerFieldsForm from "@/components/forms/InnerFieldsForm";
	
	
	import BankRequestModal from "@/components/modals/BankRequestModal";
	import HistoryTasksModal from "@/components/modals/HistoryTasksModal";
	import InputSelect from "@/components/inputs/InputSelect";
	import SetTagsModal from "@/components/modals/SetTagsModal";
	import DateTimePicker from "@/components/inputs/DateTimePicker";
    
	import {CommonDataService} from "@/services/CommonDataService";

    
    


    export default {
        name: "addEditTask",
		props: {
			returnPath: {type: String},
			clientId: {type: [ String, Number ]},
			objectId: {type: [ String, Number ]},
			funnelId: {type: [ String, Number ]},
			restoreForm: {type: [ Boolean ]},
		},
		components: {
			FormSteps,
			HistoryTasksModal,
			Loader,
			//StageBar,
			ResultsModal,
			 
			DateTimePicker, 
			Switcher, 
			SelectBox, 
			TextArea, 
			InputText
		},
        data() {
            return {
                section: 'free',
                
               // client_id: null,
                //object_id: null,
                requisition_id: null,
                stages: null,
                checklist: [],
                
                new_task_section: null,
                event_type: 'call',
                new_task_client_id: null,
                new_task_object_id: null,
                new_task_requisition_id: null,
                new_task_field_name: null,
                new_task_stage_id: null,
				who_work_responsibles: null,
				clients_list: [],
				documents_list: [],
				responsibles_list: [],
				item: {},
                 
                /************************************************** */
              
                title: {
					default: "Новая задача",
					edit: "Редактирование задачи",
				},
				form_step: 1,
				form_steps: [
					{
						id: 1,
						title: 'Звонок',
						icon: null,
					}, {
						id: 2,
						title: 'Встреча',
						icon: null,
					}, {
						id: 3,
						title: 'Показ',
						icon: null,
					}, {
						id: 4,
						title: 'Сделка',
						icon: null,
					}, {
						id: 5,
						title: 'Задача',
						icon: null,
					}
				],
				
				
				objects_list: [],
				
				fields_list: [],
				variables_list: [],
				resultsModalState: {
					state: '',
					title: '',
					message: ''
				},
				requisitions: [],
				errors: [],
				is_loading_process: true,
				requisitions_proccessed: true,
            };
        },
        methods: CommonService.mergeRecursive({
			setEvent(step){
				console.log(step);
				this.form_step = step;
				switch(step){
					case 1: this.event_type = 'call'; break;
					case 2: this.event_type = 'meet'; break;
					case 3: this.event_type = 'show'; break;
					case 4: this.event_type = 'deal'; break;
					case 5: this.event_type = 'even'; break;
				}
			},
         
			goBack(forced) {

				if (!forced) {
					/*if (this.is_form_changed && this.isEmpty(this.$props.returnPath)) {
						let confirm = CommonService.getModal('addEditClientConfirmGoBack');
						confirm.show();
					} else {*/
						let path = '/calendar';
						if (!this.isEmpty(this.$props.returnPath))
							path = this.$props.returnPath;

						let query = [];
						if (!this.isEmpty(this.item.funnel_id))
							query.push('funnel_id=' + this.item.funnel_id);

						if (!this.isEmpty(this.object_id))
							query.push('id=' + this.object_id)

						if (!this.isEmpty(this.item.id))
							query.push('client_id=' + this.item.id);

						if (this.inArray(this.$props.returnPath, [
							'/add-object',
							'/edit-object',
							'/add-requisition',
							'/edit-requisition'
						])) {
							query.push('restore_form=true');
						}

						if (query.length > 0)
							path = path + '?' + query.join('&');

						if (!this.isEmpty(this.item.id))
							path = path + '#item_' + this.item.id.toString();

						this.$router.push(path);
					//}
				} else {
					window.history.back();
				}
			},
			getClients() {
			return api.get('/common/clients', {
				params: {
					objects: this.item.objects
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getObjects()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, client]) => {

							list.push({
								value: client.id,
								name: client.name
							});
						});
					}

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getObjects()::axios', error);

			});
		},

		getDocuments() {
			return api.get('/docs/list', {
				params: {}
			})
			.then((response) => {

				CommonService.log('debug', 'getDocuments()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, document]) => {
							list.push({
								value: document.id,
								name: document.name
							});
						});
					}

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getDocuments()::axios', error);

			});
		},
		getResponsibles(){
			
			return api.get('/common/employees', {
					params: {
						add_groups: true
					}
				})
					.then((response) => {

						CommonService.log('debug', 'getResponsibles()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {

							let list = [];
							let groups = (response.data.groups) ? response.data.groups : [];
							//console.log(groups);

							if (response.data.list) {
								Object.entries(response.data.list).forEach(([key, employee]) => {

									if (employee.group_id) {

										let group_id = employee.group_id;
										if (groups[group_id]) {

											list.push({
												value: false,
												name: '<b>' + groups[group_id] + '</b>'
											});

											delete groups[group_id];
										}
									}

									let department_name = (employee.department)  ? ' (' + employee.department + ')' : '';
									list.push({
										value: employee.id,
										name: (employee.group_id) ? '&nbsp;&nbsp;-&nbsp;' + employee.name : employee.name + department_name
									});
								});
							}

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getEmployees()::axios', error);

					});
		},
			addEditEvent() {

				let fields = [];

				if (this.section == "objects") {
					if (this.event_type == 'call') {
						fields.push('schedule_date', 'schedule_date_to', 'comment');
					} else if (this.event_type == 'meet') {
						fields.push('schedule_date', 'address', 'comment');
					} else if (this.event_type == 'show') {
						fields.push('schedule_date', 'address', 'comment');
					} else if (this.event_type == 'deal') {
						fields.push('schedule_date', 'summ', 'object_id', 'document_id', 'comment');
					} else {
						fields.push('comment');
					}
				} else if (this.section == "clients") {
					if (this.event_type == 'call') {
						fields.push('schedule_date', 'schedule_date_to', 'comment');
					} else if (this.event_type == 'meet') {
						fields.push('schedule_date', 'address', 'comment');
					} else if (this.event_type == 'show') {
						fields.push('schedule_date', 'address', 'comment');
					} else if (this.event_type == 'deal') {
						fields.push('schedule_date', 'summ', 'object_id', 'document_id', 'comment');
					} else if (this.event_type == 'even') {
						fields.push('schedule_date', 'title', 'comment');
					} else if (this.event_type == 'file') {
						fields.push('files', 'comment');
					} else if (this.event_type == 'partner_id') {
						fields.push('partner_id');
					} else {
						fields.push('comment');
					}
				}/* else if (this.section == "requisitions") {

				}*/

				if (this.validateAll(fields)) {

					let data = {
						event_id: this.event_id,
						event_type: this.event_type,
						section: this.section,
						responsibles: this.who_work_responsibles,
					};
					

					if (this.section == "objects")
						data.object_id = this.object_id;
					else if (this.section == "clients")
						data.client_id = this.client_id;
					else if (this.section == "requisitions")
						data.requisition_id = this.requisition_id;

					console.log(data);		
					let task = this.item;
					console.log(task);
					api.post('/common/task', {
						task,
						...data
					}).then((response) => {
						console.log(response.data);
						/*if (process.env.NODE_ENV == "development")
							console.debug('addEditEvent()::axios', {
								response: response.data
							});*/

						if (response.status == 200 && response.data.success) {
							this.goBack();
							let tasks = response.data.tasks;
							if (tasks) {
								if (tasks.length) {

									if (!this.isEmpty(this.stage_id))
										tasks.stage_id = this.stage_id;

									if (!this.isEmpty(this.field_name))
										tasks.field_name = this.field_name;

									this.$emit('updateTasksList', tasks);
								}
							}

							let history = response.data.history;
							if (history) {
								if (history.length) {
									this.$emit('updateHistoryList', history);
								}
							}

							let modal_id = (!this.isEmpty(this.modal_id)) ? 'newTaskModal_' + this.modal_id: 'newTaskModal';
							let modal = CommonService.getModal(modal_id);
							if (modal) {
								modal.hide();
							}
						}
					}).catch(function (error) {

						CommonService.log('error', 'addEditEvent()::axios', error);

					});
				}
			},
			
			
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			formatDate(format, datetime) {
				return CommonService.formatDateTime(datetime, 'ru', format);
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			showNote(message) {
				return alert(message);
			},
			
			toRoute(url) {
				this.$store.commit('setFormsState', { 'addClient': this.item });
				this.$router.push(url);
			},
			icon(path, class_name, title, size) {
				return CommonService.buildIconImage(path, class_name, title, size);
			},

			setAndValidate(name, value) {
			if(name == 'responsible_id'){
				this.who_work_responsibles = value;
			} else {
			

			if (typeof name !== 'string')
				return;

			if (value && typeof value == 'object') {
				if (typeof value.target !== 'undefined')
					return;
			}

			if (!this.inArray(typeof (value), ['object', 'number', 'string', 'boolean']))
				return;

			let errors = [];
			let parent = null;
			let child = null;
			let parts = name.split('.', 2);
			if (parts.length == 2) {
				parent = parts[0];
				child = parts[1];
			}

			// Валидация значения
			if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {

				/*if ('invalid' in this.$refs[name])
					this.$refs[name].invalid = false;*/

				if (this.$refs[name].inputLabel) {
					let label = this.$refs[name].inputLabel.replace(/:+$/, '');

					let is_skip = false;
					if (name == 'funnel_id' && value == 0)
						is_skip = true;

					if (this.$refs[name].required && !is_skip) {
						if (value) {
							if (value.toString() == '0' || value.toString() == '-1')
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							else if (this.isEmpty(value))
								errors.push('Поле `' + label + '` обязательно к заполнению!');
						} else {
							errors.push('Поле `' + label + '` обязательно к заполнению!');
						}
					}

					if (!this.isEmpty(errors)) {

						this.errors[name] = {
							name: name,
							label: label,
							errors: errors
						};

						this.$refs[name].valid = false;
						this.$refs[name].invalid = errors.join('<br/>');
					} else {
						this.$refs[name].valid = true;
						this.$refs[name].invalid = false;
					}

				}
			}

			// Установка значения
			if (parent && child) {
				if (!this.isEmpty(this.item[parent])) {
					if (typeof (this.item[parent][child]) !== "undefined") {
						this.item[parent][child] = value;
						
					} else {
						this.item[parent] = {
							...{
								[child]: value
							},
							...this.item[parent]
						};
					}
				} else {
					this.item[parent] = {
						[child]: value
					};
				}
			} else if (parent == null && name) {
				this.item[name] = value;
			} else {
				if (parent && child)
					this.item[parent][child] = null;
				else
					this.item[name] = null;
			}

			this.is_form_changed = true;

			CommonService.log('debug', 'setAndValidate()', { name: name, value: value, errors: errors });
			}

		},
		validateAll(fields) {

			this.errors = [];
			let is_valid = false;
			let invalid_input = null;
			fields.forEach(name => {
				let value = (typeof (this.item[name]) !== "undefined") ? this.item[name] : null;
				this.setAndValidate(name, value);
			});

			if (typeof Object.values(this.errors) == "object") {
				this.errors = CommonService.removeEmpty(Object.values(this.errors));
				//this.errors = CommonService.resetArray(Object.values(this.errors));
				this.errors = Object.values(this.errors);
				if (typeof (this.errors[0]) !== "undefined") {
					if (!this.isEmpty(Object.values(this.errors)[0]['name'])) {
						let invalid_input = Object.values(this.errors)[0]['name'];
						if (invalid_input && typeof invalid_input !== "undefined") {
							let input = this.$refs[invalid_input].$el;
							if (input) {
								this.$nextTick(() => CommonService.scrollIntoView(input, 60));
							}
						}
					}
				}
			}

			is_valid = this.isEmpty(this.errors);

			CommonService.log('debug', 'validateAll()', {fields: fields, is_valid: is_valid, errors: this.errors});

			return is_valid;
			},
			
			getCustomFieldValue(key) {

				if (!this.isEmpty(key) && !this.isEmpty(this.item.fields))
					return this.item.fields[key];

				return null;
			},
        }, CommonDataService.methods),
        mounted() {
        
             //this.$refs.checkListTaskModal.showModal();  
              this.is_loading_process = false; 
			  this.getClients().then((data) => {
				this.clients_list = data
			});

			this.getDocuments().then((data) => {
				this.documents_list = data
			});
			
			this.getResponsibles().then((data) => {
				this.responsibles_list = data
			});   
			
        },
		watch: {
			variables_list(variables, oldVariables) {

				/*if (typeof variables == "object") {
					if (!this.isEmpty(variables)) {
						variables.forEach((variable) => {
							if (this.isEmpty(variable.value) && this.isEmpty(variable.default)) {
								variable.value = variable.default;
							}
						});

						this.variables_list = variables;
					}
				}

				CommonService.log('debug', 'watch::variables_list', this.variables_list);*/

				this.$emit('setVariables', this.variables_list);
			},
			client_id: function() {

				this.client_id = this.clientId ?? null;

				CommonService.log('debug', 'watch::client_id', this.client_id);

				return this.client_id;
			},
			search_query(value, oldValue) {
				if ((value !== oldValue && (value.length >= 3 || oldValue.length >= 3)) || value.length == 0) {
					this.getRequisitionsList().then(data => {
						this.requisitions_list = data;
					});
				}
			},
			requisitions_offset(value, oldValue) {
				if (value !== oldValue && value != 0) {
					this.getRequisitionsList().then(data => {
						this.requisitions_list = [...this.requisitions_list, ...data];
					});
				}
			},
		},
    };
</script>

<style lang="scss">
  .fix-width {
    width: 100px;
    text-align: right;
  }
	.add-client {
		.step-list {
			@media(max-width: 480px) {
				flex-direction: column;
				align-items: flex-start !important;

				li:not(:last-child) {
					margin-bottom: 20px;
				}
			}
		}
	}
	

	.modal-fullscreen {
		width: auto;
	}
</style>
