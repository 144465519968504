<template>
	<div>
		<div class="modal fade" id="bankRequestModal" ref="bankRequestModal" tabindex="-1" aria-labelledby="Добавить банк" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header d-flex mb-20 border-0 p-1">
						<span v-if="this.section == 'request-bank'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Подача в банк</span>
						<span v-else-if="this.section == 'resolve-bank'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Заявка одобрена банком</span>
						<span v-else class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Добавить банк</span>
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div v-if="!this.isEmpty(this.banks_list)" class="modal-body d-flex flex-column p-1">
						<div class="row">
							<SelectBox inputId="bank"
									   inputLabel="Банк"
									   inputName="bank"
									   :inputValue="(!this.isEmpty(this.bank_id)) ? this.bank_id : null"
									   :inputOptions="this.banks_list"
									   ref="bank"
									   inputLabelClass="text-font-secondary mb-3"
									   inputClass="rounded-3 p-2"
									   inputWrapClass="mb-20"
									   inputSearch="true" @setValue="value => this.bank_id = value" />
						</div>
						<div v-if="this.section == 'request-bank'" class="row">
							<InputText inputId="employee"
									   inputLabel="Ответственный сотрудник в банке"
									   inputName="employee"
									   :inputValue="(!this.isEmpty(this.employee)) ? this.employee : null"
									   inputClass="rounded-3 p-2"
									   inputLabelClass="text-font-secondary mb-3"
									   inputWrapClass="mb-20"
									   ref="employee"
									   @setValue="value => this.employee = value" />

							<DateTimePicker inputId="date"
											inputLabel="Дата подачи"
											inputName="date"
											:inputValue="(!this.isEmpty(this.date)) ? this.date : null"
											inputClass="rounded-3 p-2"
											inputLabelClass="text-font-secondary mb-3"
											inputWrapClass="mb-20"
											displayFormat="DD.MM.YYYY"
											ref="date"
											@setValue="value => this.date = value" />
						</div>
						<div v-else-if="this.section == 'resolve-bank'" class="row">
							<InputText inputId="summa"
									   inputLabel="Одобренная сумма"
									   inputName="summa"
									   :inputValue="(!this.isEmpty(this.summa)) ? this.summa : null"
									   inputClass="rounded-3 p-2"
									   inputLabelClass="text-font-secondary mb-3"
									   inputWrapClass="mb-20"
									   ref="summa"
									   @setValue="value => this.summa = value" />

							<InputText inputId="prstavka"
									   inputLabel="Процентная ставка"
									   inputName="prstavka"
									   :inputValue="(!this.isEmpty(this.prstavka)) ? this.prstavka : null"
									   inputClass="rounded-3 p-2"
									   inputLabelClass="text-font-secondary mb-3"
									   inputWrapClass="mb-20"
									   ref="prstavka"
									   @setValue="value => this.prstavka = value" />

							<InputText inputId="srok"
									   inputLabel="Срок кредитования"
									   inputName="srok"
									   :inputValue="(!this.isEmpty(this.srok)) ? this.srok : null"
									   inputClass="rounded-3 p-2"
									   inputLabelClass="text-font-secondary mb-3"
									   inputWrapClass="mb-20"
									   ref="srok"
									   @setValue="value => this.srok = value" />

							<TextArea inputId="usloviya"
									  inputLabel="Обязательные условия"
									  inputName="usloviya"
									  :inputValue="(!this.isEmpty(this.usloviya)) ? this.usloviya : null"
									  inputClass="rounded-3 p-2"
									  inputLabelClass="text-font-secondary mb-3"
									  inputWrapClass="mb-20"
									  ref="usloviya"
									  @setValue="value => this.usloviya = value" />
						</div>
					</div>
					<div v-else class="modal-body d-flex flex-column p-1">
						<Loader />
					</div>
					<div class="modal-footer border-0 p-1">
						<button type="button"
								class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
								:disabled="this.isEmpty(this.banks_list)"
								data-bs-dismiss="modal"
								@click="this.addEditRequest">
							Сохранить
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import api from "@/api";
import CommonService from "@/services/CommonService";
import Loader from "@/components/common/Loader";
import TextArea from "@/components/inputs/TextArea";
import InputText from "@/components/inputs/InputText";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import SelectBox from "@/components/inputs/SelectBox";

export default {
	name: 'BankRequestModal',
	components: {SelectBox, DateTimePicker, InputText, TextArea, Loader},
	data() {
		return {
			section: 'request-bank',
			request: [],
			banks_list: [],
			bank_id: null,
			employee: null,
			date: null,
			summa: null,
			prstavka: null,
			srok: null,
			usloviya: null,
		}
	},

	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		sortArray(data) {
			return CommonService.sortArray(data);
		},
		getList(section) {
			return api.get('/common/list', {
				params: {
					section: section
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getList()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, item]) => {
							list.push({
								value: item.id,
								name: item.name
							});
						});
					}
					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getList()::axios', error);

			});
		},
		addEditRequest() {

			let bank_id = this.bank_id;
			let bank_name = (this.banks_list.find(bank => bank.value === bank_id).name).toString();

			this.request = [];
			if (this.section == 'request-bank') {
				this.request[bank_id] = {
					id: bank_id,
					name: bank_name,
					employee: this.employee,
					date: this.date,
				};
			} else if (this.section == 'resolve-bank') {
				this.request[bank_id] = {
					id: bank_id,
					name: bank_name,
					summa: this.summa,
					prstavka: this.prstavka,
					srok: this.srok,
					usloviya: this.usloviya,
				};
			}

			this.$emit('addEditRequest', {section: this.section, request: this.request});
		},

		// banks: {"6":{"employee":"Иванов","date":"05.06.2022","name":"Абсолют банк"}}
		// resolve: {"31":{"name":"АК Барс Банк","summa":5555,"prstavka":55,"srok":365,"usloviya":"Йцукен!"}}
	},
	mounted() {
		var _this = this;
		var modal = this.$refs.bankRequestModal;
		if (modal && typeof modal !== "undefined") {

			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.request = [];
				_this.banks_list = [];
				_this.bank_id = null;
				_this.summa = null;
				_this.prstavka = null;
				_this.srok = null;
				_this.usloviya = null;
				_this.employee = null;
				_this.date = null;
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('show.bs.modal', function (event) {
				let target = event.relatedTarget

				_this.banks_list = [];
				_this.bank_id = null;
				_this.summa = null;
				_this.prstavka = null;
				_this.srok = null;
				_this.usloviya = null;
				_this.employee = null;
				_this.date = null;

				if (!_this.isEmpty(_this.request)) {

					if (!_this.isEmpty(_this.request.id))
						_this.bank_id = _this.request.id;

					if (!_this.isEmpty(_this.request.summa))
						_this.summa = _this.request.summa;

					if (!_this.isEmpty(_this.request.prstavka))
						_this.prstavka = _this.request.prstavka;

					if (!_this.isEmpty(_this.request.srok))
						_this.srok = _this.request.srok;

					if (!_this.isEmpty(_this.request.usloviya))
						_this.usloviya = _this.request.usloviya;

					if (!_this.isEmpty(_this.request.employee))
						_this.employee = _this.request.employee;

					if (!_this.isEmpty(_this.request.date))
						_this.date = _this.request.date;

				}

				_this.getList('banks_list').then(data => { _this.banks_list = _this.sortArray(data) });

				let section = target.getAttribute('data-bs-section');
				if (typeof section !== "undefined") {
					_this.section = section;
				}

				let request = target.getAttribute('data-bs-request');
				if (typeof request !== "undefined") {
					if (!_this.isEmpty(request)) {
						_this.request = request;
					}
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('bankRequestModal::show.bs.modal', {
						request: _this.request,
						banks_list: _this.banks_list
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		egrn_count () {
			if (!this.isEmpty(this.$store.getters.userInfo)) {
				if (typeof this.$store.getters.userInfo.services.egrn_count !== "undefined") {
					return this.$store.getters.userInfo.services.egrn_count;
				}
			}

			return 0;
		}
	},
}
</script>